import axios from "axios";

export const citiesService = {
    async getCities(params) {
        return (await axios.get('/cities/', { params: params })).data
    },
    async postCity(data) {
        return (await axios.post('/cities/', data)).data
    },
    async deleteCity(id) {
        return (await axios.delete(`/cities/${id}/`)).data
    },
    async patchCity(data) {
        return (await axios.patch(`/cities/${data.id}/`, data)).data
    },
}

