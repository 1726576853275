<template>
  <div class="navigation_bar" id="style-1">
    <div class="inner_wrapper">
      <div v-for="item in navigationItems" :key="item.to">
        <div
          v-if="!item.children || item.hideChildrens"
          @click="listModel = {}"
        >
          <router-link
            tag="div"
            class="nav_item"
            :to="item.to"
            :style="[
              item.icon ? { paddingLeft: '50px' } : { paddingLeft: '12px' },
            ]"
            v-ripple="{ class: 'primary--text' }"
            @click.native="onClick(item)"
          >
            <div
              v-if="item.icon"
              class="nav_icon"
              :style="{
                mask: `url(${item.icon}) no-repeat center / contain`,
                '-webkit-mask': `url(${item.icon}) no-repeat center / contain`,
              }"
            ></div>
            {{ item.title }}
          </router-link>
        </div>
        <v-list-group
          v-else
          :key="item.to"
          :value="$route.path.includes(item.to)"
          v-model="listModel[item.to]"
        >
          <template #activator>
            <div
              :style="[
                item.icon ? { paddingLeft: '50px' } : { paddingLeft: '12px' },
              ]"
              style="width: 100%"
            >
              <div
                v-if="item.icon"
                class="nav_icon"
                :style="{
                  mask: `url(${item.icon}) no-repeat center / contain`,
                  '-webkit-mask': `url(${item.icon}) no-repeat center / contain`,
                }"
              ></div>
              {{ item.title }}
            </div>
          </template>
          <div @click="childClick(item.to)">
            <router-link
              v-for="child in item.children"
              :key="child.to"
              tag="div"
              class="nav_item"
              :to="child.to"
              :style="{ paddingLeft: '50px' }"
              v-ripple="{ class: 'primary--text' }"
            >
              {{ child.title }}
              <div
                v-if="item.to === '/requests'"
                class="request_count"
                :key="type_key"
              >
                {{ child.count }}
              </div>
            </router-link>
          </div>
        </v-list-group>
      </div>
      <div
        class="nav_item"
        :style="{ paddingLeft: '50px' }"
        v-ripple="{ class: 'primary--text' }"
        @click="logOut"
      >
        <div
          class="nav_icon"
          :style="{
            mask: `url(${exitIcon}) no-repeat center / contain`,
            '-webkit-mask': `url(${exitIcon}) no-repeat center / contain`,
          }"
        ></div>
        Выйти
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { orderRequestService } from "@/services/orderRequests.service.js";
export default {
  data: () => ({
    exitIcon: require("@/assets/images/navigation/exit.svg"),
    listModel: {},
    type_key: Date.now(),
  }),
  computed: {
    ...mapGetters({
      navigationItems: "navigation/navigationItems",
      getTriggerRequest: "lists/getTriggerRequest",
    }),
  },
  watch: {
    getTriggerRequest() {
      if (this.$store.getters["auth/getUser"].role != "admin") {
        this.getNotSeenRequests();
      }
    },
  },
  created() {
    if (this.$store.getters["auth/getUser"].role != "admin")
      this.getNotSeenRequests();
  },
  methods: {
    async getNotSeenRequests() {
      // this.listModel = {};
      let res = await orderRequestService.getNotSeenRequests();
      let obj = {
        requests_all: "all",
        requests_accepted: "confirmed",
        requests_unaccepted: "not_confirmed",
        requests_return_request: "to_refund",
        requests_returned: "refunded",
        requests_rejected: "canceled",
      };
      this.type_key = Date.now();
      this.navigationItems.forEach((e) => {
        if (e.to === "/requests") {
          e.children.forEach((j) => {
            j.count = res[obj[j.name]];
          });
        }
      });
    },
    logOut() {
      this.$store.commit("auth/SET_CURRENT_USER", {});
      this.$router.push("/auth");
    },
    onClick(item) {
      if (item.to === "/booking") {
        this.$router.push("/booking/residence");
      }
    },
    childClick(to) {
      Object.keys(this.listModel).forEach((key) => {
        if (key == to) {
          this.listModel[key] = true;
        } else {
          this.listModel[key] = false;
        }
      });
    },
  },
};
</script>

.<style lang="scss" scoped>
.navigation_bar {
  overflow-y: auto;
  position: absolute;
  top: 70px;
  bottom: 130px;
  background: #fff;
  width: 283px;
  padding-top: 42px;
  padding-right: 12px;
  padding-left: 12px;
  padding-bottom: 12px;
}
.nav_item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 39px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 12px;
  position: relative;
  font-family: "Gilroy", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #021011;
  padding-right: 6px;
  transition: 0.2s;
}
.nav_icon {
  background-color: #52b1ba;
  width: 23px;
  height: 20px;
  position: absolute;
  left: 12px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
.request_count {
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: #52b1ba;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  font-family: "Gilroy", sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  transition: 0.2s;
  padding-top: 2px;
  padding-right: 1px;
  color: #fff;
}

.router-link-active,
.router-link-exact-active {
  background: #52b1ba;
  color: #fff;
  .nav_icon {
    background-color: #fff;
  }
  .request_count {
    background-color: #fff;
    color: #52b1ba;
  }
}
</style>