<template>
  <div class="vue_footer">
    <div class="section_wrapper">
      <p class="footer_title">Контакты Kinetica Group</p>
      <div class="contacts_wrapper">
        <div v-for="(item, index) in items" :key="index">
          <p>{{item.description}}</p>
          <p>{{item.phone_number | VMask('+# (###) ### ##-##')}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { contactsService } from '@/services/contacts.service.js'
export default {
  data: () => ({
    items: []
  }),
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const { results } = await contactsService.getContacts()
      this.items = results
    }
  }
}
</script>

<style lang="scss" scoped>
.vue_footer {
  background: #F5F5F5;
  width: 100%;
  padding-top: 30px;
  padding-bottom: 20px;
  // z-index: 1;
}
.footer_title {
  font-family: 'Gilroy', sans-serif;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  color: #021011;
  margin-bottom: 32px;
}
.contacts_wrapper {
  // display: flex;
  // justify-content: space-between;
  // flex-wrap: wrap;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 20px;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    // margin-bottom: 12px; 
    p {
      margin-bottom: 0;
      font-family: 'Gilroy', sans-serif;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      &:first-of-type{
        color: #021011;
      }
      &:last-of-type {
        margin-left: 12px;
        cursor: pointer;
        color: #FF8413;
      }
    }
  }
}
</style>