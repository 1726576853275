import axios from "axios";

export const toursService = {
    async getNearDates(params) {
        return (await axios.get(`/active-tour-near-dates/`, {params})).data
    },
    async postTourOrders(data) {
        return (await axios.post(`/tour-orders/`, data)).data
    },
    async activeTourBuses(id) {
        return (await axios.get(`/active-tour-buses/${id}/`)).data
    },
    async bookSeatPlaces({id, data}) {
        return (await axios.patch(`/active-tour-seat-places/${id}/`, data)).data
    },
    async getSingleActiveTours(params) {
        return (await axios.get(`/active-tours/${params.id}/`, {params})).data
    },
    async getSingleCard(id) {
        return (await axios.get(`/tour-cards/${id}/`)).data
    },
    async getActiveTours(params) {
        return (await axios.get(`/active-tours/`, {params})).data
    },
    async patchBusSeatPlace(data) {
        return (await axios.patch(`/tour-bus-seat-places/${data.id}/`, data)).data
    },
    async postSeatPlace(data) {
        return (await axios.post(`/tour-bus-seat-places/`, data)).data
    },
    async deleteBusSeatPlace(data) {
        return (await axios.delete(`/tour-bus-seat-places/${data.id}/`)).data
    },
    async getSingleTourBus(id) {
        return (await axios.get(`/tour-buses/${id}/`)).data
    },
    async deleteTourBus(id) {
        return (await axios.delete(`/tour-buses/${id}/`)).data
    },
    async patchTourRoute(data) {
        return (await axios.put(`/tour-routes/${data.id}/`, data)).data
    },
    async deleteTourRoute(id) {
        return (await axios.delete(`/tour-routes/${id}/`)).data
    },
    async getTourRoutes(params) {
        return (await axios.get('/tour-routes/' , {params})).data
    },
    async createTourRoute(data) {
        return (await axios.post('/tour-routes/' , data)).data
    },
    async getBuses(params) {
        return (await axios.get('/tour-buses/' , {params})).data
    },
    async createBus(data) {
        return (await axios.post('/tour-buses/' , data)).data
    },
    async createTour(data) {
        return (await axios.post('/tours/' , data)).data
    },
    async getGallery(id) {
        return (await axios.get(`/tour-images/`, {params: {tour: id}})).data
    },
    async deleteImage(id) {
        return (await axios.delete(`/tour-images/${id}/`)).data
    },
    async patchTour(data) {
        return (await axios.patch(`/tours/${data.get('id')}/` , data)).data
    },
    async uploadMultiple(data) {
        return (await axios.post('/tour-images/' , data)).data
    },
    async getTours(params) {
        return (await axios.get('/tours/' , {params})).data
    },
    async getToursFilter(params){
        return (await axios.get('/main-tours/' , {params})).data
    },
    async getAllTours(params) {
        return (await axios.get('/main-tours/all/' , {params})).data
    },
    async getTourLocations(params) {
        return (await axios.get('/tour-locations/' , {params})).data
    },
}
