import axios from "axios";

export const authService = {
    async login(data) {
        return (await axios.post('/auth/jwt/create/' , data)).data
    },
    async me() {
        return (await axios.get('/auth/users/me/')).data
    },
    async refreshToken(data) {
        return (await axios.post('/auth/jwt/refresh/' , data)).data
    },
    async getUsers(params){
        return (await axios.get(`/auth/users/`, { params })).data
    },
}

