import Vue from 'vue'
import VueRouter from 'vue-router'
import Auth from "../views/auth/index.vue";
import store from "@/store";

Vue.use(VueRouter);

export const routes = [{
    path: "/private",
    name: "private",
    hideInNavigation: true,
    permission: ["operator", "admin", "agent"],
    component: () => import("../views/private/index.vue"),
  },
  {
    path: "/auth",
    name: "auth",
    component: Auth,
    hideInNavigation: true,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/booking",
    name: "booking",
    title: "Бронирование",
    icon: require("@/assets/images/navigation/booking.svg"),
    permission: ["operator", "agent"],
    component: () => import("../views/booking/index.vue"),
    hideChildrens: true,
    children: [{
        path: "/booking/residence",
        name: "booking_residence",
        hideInNavigation: true,
        permission: ["admin", "operator", "agent"],
        component: () => import("../views/booking/residence/index.vue"),
      },
      {
        path: "/booking/trains",
        name: "booking_trains",
        hideInNavigation: true,
        permission: ["admin", "operator", "agent"],
        component: () => import("../views/booking/trains/index.vue"),
      },
      {
        path: "/booking/buses",
        name: "booking_buses",
        hideInNavigation: true,
        permission: ["admin", "operator", "agent"],
        component: () => import("../views/booking/buses/index.vue"),
      },
      {
        path: "/booking/tours",
        name: "booking_tours",
        hideInNavigation: true,
        permission: ["admin", "operator", "agent"],
        component: () => import("../views/booking/tours/index.vue"),
      },
      {
        path: "/booking/transfer",
        name: "booking_transfer",
        hideInNavigation: true,
        permission: ["admin", "operator", "agent"],
        component: () => import("../views/booking/transfer/index.vue"),
      },
    ]
  },
  {
    path: "/administration",
    name: "administration",
    title: "Администрирование",
    icon: require("@/assets/images/navigation/admin.svg"),
    permission: ["operator", "agent"],
    component: () => import("../views/administration/index.vue"),
    children: [{
        path: "/administration/block",
        name: "administration_block",
        title: "Заведение блоков",
        permission: ["operator"],
        component: () => import("../views/administration/block/index.vue"),
      },
      {
        path: "/administration/sales",
        name: "administration_sales",
        title: "Динамика продаж блоков",
        permission: ["operator"],
        component: () => import("../views/administration/sales/index.vue"),
      },
      {
        path: "/administration/employees",
        name: "administration_employees",
        title: "Мои сотрудники",
        permission: ["operator", "agent"],
        component: () => import("../views/administration/employees/index.vue"),
      },
      {
        path: "/administration/news",
        name: "administration_news",
        title: "Новостная лента",
        permission: ["operator", "agent"],
        component: () => import("../views/administration/news/index.vue"),
      },
    ],
  },
  {
    path: "/requests",
    name: "requests",
    title: "Заявки",
    icon: require("@/assets/images/navigation/requests.svg"),
    permission: ["operator", "agent"],
    component: () => import("../views/requests/index.vue"),
    children: [{
        path: "/requests/all",
        name: "requests_all",
        title: "Все",
        permission: ["operator", "agent"],
        component: () => import("../views/requests/all/index.vue"),
      },
      {
        path: "/requests/all/:id",
        name: "requests_all_id",
        title: "Заявка",
        hideInNavigation: true,
        permission: ["operator", "agent"],
        component: () => import("../views/requests/all/_id.vue"),
      },
      {
        path: "/requests/accepted",
        name: "requests_accepted",
        title: "Подтверждённые",
        permission: ["operator", "agent"],
        component: () => import("../views/requests/accepted/index.vue"),
      },
      {
        path: "/requests/accepted/:id",
        name: "requests_accepted_id",
        title: "Заявка",
        hideInNavigation: true,
        permission: ["operator", "agent"],
        component: () => import("../views/requests/accepted/_id.vue"),
      },
      {
        path: "/requests/unaccepted",
        name: "requests_unaccepted",
        title: "Неподтверждённые",
        permission: ["operator", "agent"],
        component: () => import("../views/requests/unaccepted/index.vue"),
      },
      {
        path: "/requests/unaccepted/:id",
        name: "requests_unaccepted_id",
        title: "Заявка",
        hideInNavigation: true,
        permission: ["operator", "agent"],
        component: () => import("../views/requests/unaccepted/_id.vue"),
      },
      {
        path: "/requests/return_request",
        name: "requests_return_request",
        title: "Запросы на возврат",
        permission: ["operator", "agent"],
        component: () => import("../views/requests/return_request/index.vue"),
      },
      {
        path: "/requests/return_request/:id",
        name: "requests_return_request_id",
        title: "Заявка",
        hideInNavigation: true,
        permission: ["operator"],
        component: () => import("../views/requests/return_request/_id.vue"),
      },
      {
        path: "/requests/returned",
        name: "requests_returned",
        title: "Возвращенные заявки",
        permission: ["operator", "agent"],
        component: () => import("../views/requests/returned/index.vue"),
      },
      {
        path: "/requests/returned/:id",
        name: "requests_returned_id",
        title: "Заявка",
        hideInNavigation: true,
        permission: ["operator", "agent"],
        component: () => import("../views/requests/returned/_id.vue"),
      },
      {
        path: "/requests/rejected",
        name: "requests_rejected",
        title: "Отклонённые",
        permission: ["operator", "agent"],
        component: () => import("../views/requests/rejected/index.vue"),
      },
      {
        path: "/requests/rejected/:id",
        name: "requests_rejected_id",
        title: "Заявка",
        hideInNavigation: true,
        permission: ["operator", "agent"],
        component: () => import("../views/requests/rejected/_id.vue"),
      },
    ],
  },
  {
    path: "/myTourAgents",
    name: "myTourAgents",
    title: "Мои турагенты",
    icon: require("@/assets/images/navigation/myTourAgents.svg"),
    permission: ["operator"],
    component: () => import("../views/myTourAgents/index.vue"),
  },
  {
    path: "/myTourAgents/:id",
    name: "myTourAgents_id",
    title: "Мои турагенты",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/myTourAgents/_id.vue"),
  },
  {
    path: "/reports",
    name: "reports",
    title: "Отчеты",
    icon: require("@/assets/images/navigation/reports.svg"),
    permission: ["operator", "agent"],
    component: () => import("../views/reports/index.vue"),
  },
  {
    path: "/tours",
    name: "tours",
    title: "Туры",
    permission: ["admin"],
    component: () => import("../views/admin/tours/index.vue"),
  },
  {
    path: "/tourAgent",
    name: "patchContact",
    title: "Турагенты",
    permission: ["admin"],
    component: () => import("../views/admin/tourAgent/index.vue"),
  },
  {
    path: "/tourAgent/:id",
    name: "patchContact_id",
    hideInNavigation: true,
    permission: ["admin"],
    component: () => import("../views/admin/tourAgent/_id.vue"),
  },
  {
    path: "/tourOperator",
    name: "tourOperator",
    title: "Туроператоры",
    permission: ["admin"],
    component: () => import("../views/admin/tourOperator/index.vue"),
  },
  {
    path: "/tourOperator/:id",
    name: "tourOperator_id",
    hideInNavigation: true,
    title: "Туроператоры",
    permission: ["admin"],
    component: () => import("../views/admin/tourOperator/_id.vue"),
  },
	{
    path: "/users",
    name: "users",
    title: "Пользователи",
    permission: ["admin"],
    component: () => import("../views/admin/users/index.vue"),
  },
  {
    path: "/contacts",
    name: "contacts",
    title: "Контакты",
    permission: ["admin"],
    component: () => import("../views/admin/contacts.vue"),
  },
  {
    path: "/locations",
    name: "locations",
    title: "Местности",
    permission: ["admin"],
    component: () => import("../views/admin/locations.vue"),
  },
  {
    path: "/hotels",
    name: "hotels",
    title: "Отели",
    permission: ["admin"],
    component: () => import("../views/admin/hotels/index.vue"),
  },
  {
    path: "/cities",
    name: "cities",
    title: "Города",
    permission: ["admin"],
    component: () => import("../views/admin/cities/index.vue"),
  },
  {
    path: "/stations",
    name: "stations",
    title: "Станции",
    permission: ["admin"],
    component: () => import("../views/admin/stations.vue"),
  },
  {
    path: "/agreement",
    name: "agreement",
    title: "Пользовательское соглашение",
    permission: ["admin"],
    component: () => import("../views/admin/agreement.vue"),
  },
  {
    path: "/logs",
    name: "logs",
    title: "Журнал действий",
    permission: ["admin"],
    component: () => import("../views/logs/index.vue"),
  },
  // residence
  {
    path: "/booking/residence/:id",
    name: "booking_residence_id",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/booking/residence/_id/index.vue"),
  },
  {
    path: "/booking/residence/:id/booking",
    name: "booking_residence_id_booking",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/booking/residence/_id/booking.vue"),
  },
  // buses
  {
    path: "/administration/block/buses",
    name: "administration_block_buses",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/administration/block/buses/index.vue"),
  },
  {
    path: "/booking/buses/ow/:id",
    name: "booking_buses_ow_id",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/booking/buses/ow/selectPlace.vue"),
  },
  {
    path: "/booking/buses/ow/:id/booking",
    name: "booking_buses_ow_id_booking",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/booking/buses/ow/booking.vue"),
  },
  {
    path: "/booking/buses/rt/:id",
    name: "booking_buses_rt_id",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/booking/buses/rt/selectPlace/index.vue"),
  },
  {
    path: "/booking/buses/rt/:id/search",
    name: "booking_buses_rt_id_search",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/booking/buses/rt/selectPlace/secondSearch/index.vue"),
  },
  {
    path: "/booking/buses/rt/:id/search/:second",
    name: "booking_buses_rt_id_search_second",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/booking/buses/rt/selectPlace/secondSearch/selectPlace/index.vue"),
  },
  {
    path: "/booking/buses/rt/:id/search/:second/booking",
    name: "booking_buses_rt_id_search_second_booking",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/booking/buses/rt/selectPlace/secondSearch/selectPlace/booking/index.vue"),
  },
  // tours
  {
    path: "/administration/block/tours",
    name: "/administration_block_tours",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/administration/block/tours/index.vue"),
  },
  {
    path: "/booking/tours/:id",
    name: "/booking_tours_id",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/booking/tours/placeSelect/index.vue"),
  },
  {
    path: "/booking/tours/:id/card",
    name: "/booking_tours_id_card",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/booking/tours/placeSelect/card/index.vue"),
  },
  // trains
  {
    path: "/administration/block/trains",
    name: "administration_block_trains",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/administration/block/trains/index.vue"),
  },
  {
    path: "/administration/block/trains/:id",
    name: "administration_block_trains_id",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/administration/block/trains/_id.vue"),
  },
  {
    path: "/booking/trains/ow/:id",
    name: "booking_trains_ow_id",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/booking/trains/ow/selectPlace.vue"),
  },
  {
    path: "/booking/trains/ow/:id/booking",
    name: "booking_trains_ow_id_booking",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/booking/trains/ow/booking.vue"),
  },
  {
    path: "/booking/trains/rt/:id",
    name: "booking_trains_rt_id",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/booking/trains/rt/selectPlace/index.vue"),
  },
  {
    path: "/booking/trains/rt/:id/search",
    name: "booking_trains_rt_id_search",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/booking/trains/rt/selectPlace/secondSearch/index.vue"),
  },
  {
    path: "/booking/trains/rt/:id/search/:second",
    name: "booking_trains_rt_id_search_second",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/booking/trains/rt/selectPlace/secondSearch/selectPlace/index.vue"),
  },
  {
    path: "/booking/trains/rt/:id/search/:second/booking",
    name: "booking_trains_rt_id_search_second_booking",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/booking/trains/rt/selectPlace/secondSearch/selectPlace/booking/index.vue"),
  },
  // sales start
  // buses
  {
    path: "/administration/sales/buses",
    name: "administration_sales_buses",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/administration/sales/buses/index.vue"),
  },
  {
    path: "/administration/sales/buses/:id",
    name: "administration_sales_buses_id",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/administration/sales/buses/_id/index.vue"),
  },
  // residence
  {
    path: "/administration/sales/residence",
    name: "administration_sales_residence",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/administration/sales/residence/index.vue"),
  },
  // tours
  {
    path: "/administration/sales/tours",
    name: "administration_sales_tours",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/administration/sales/tours/index.vue"),
  },
  {
    path: "/administration/sales/tours/:id",
    name: "administration_sales_tours_id",
    hideInNavigation: true,
    permission: ["operator"],
    component: () => import("../views/administration/sales/tours/_id/index.vue"),
  },
  {
    path: "/reportsAdmin",
    name: "reports_admin",
    title: "Отчеты",
    permission: ["admin"],
    component: () => import("@/views/reportsAdmin/index.vue"),
  },
];
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  const notLogged = store.getters["auth/notLogged"];
  if (to.path == '/') {
    router.push('/booking/residence')
  }
  if (to.name != 'auth' && notLogged) {
    store.commit("auth/SET_CURRENT_USER", {});
    router.push('/auth')
  }
  next()
});

export default router