export default {
  required: (value) => {
    return typeof value === "boolean" || typeof value === "number"
      ? true
      : !!value || "Обязательное поле";
  },
  notEmptyArray: (value) => {
    return !!value.length || "Обязательное поле";
  },
  email: (value) => {
    const cyrillicPattern = /[а-яА-ЯЁё]/;
    const emailPattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (
      (!cyrillicPattern.test(value) && emailPattern.test(value)) ||
      "Некорректный e-mail"
    );
  },
  numberMaxMin: (max, min) => (value) => {
    if (max && value > max) {
      return `Не должно быть больше ${max}`;
    } else if (min !== undefined && value < min) {
      return `Не должно быть меньше ${min}`;
    }
    return true;
  },
  unique: (items) => (value) => {
    return !items.includes(value) || "Дубликат";
  },
  stringLengthMaxMin: (max, min) => (value) => {
    if (max && value?.length > max) {
      return `Не должно быть больше ${max} символов`;
    } else if (min && value?.length < min) {
      return `Не должно быть меньше ${min} символов`;
    }
    return true;
  },
  none: (value) => {
    return true
  }
};
