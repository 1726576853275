<template>
    <v-card class="auth_card" width="500">
        <v-card-title class="justify-center text-h4 auth_title">Авторизация</v-card-title>
        <v-card-text>
            <v-form ref="form">
                <v-text-field v-model="userModel.email" label="E-mail" outlined dense :rules="[$rules.email]"/>
                <v-text-field
                    v-model="userModel.password"
                    :append-icon="eyePass ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="[$rules.required]"
                    :type="eyePass ? 'text' : 'password'"
                    label="Пароль"
                    dense
                    outlined
                    @click:append="eyePass = !eyePass"
                ></v-text-field>
                <v-btn @click.prevent="submitHandler" class="mt-5" type="submit" color="primary" block>Войти</v-btn>
            </v-form>
        </v-card-text>
  </v-card>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
    data: () => ({
        eyePass: false,
        userModel: {}
    }),
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            notLogged: 'auth/notLogged',
        })
    },
    methods: {
        ...mapActions({
            login: 'auth/login'
        }),
        async submitHandler() {
            try {
                if (!this.$refs.form.validate()) return
                this.$store.commit('ui/SET_LOADING', true)
                await this.login(this.userModel)
                this.$router.push('/booking/residence')
            } catch(e) {
                console.error(e);
            } finally {
                this.$store.commit('ui/SET_LOADING', false)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.auth_card {
    margin: 18px auto 0;
}
.auth_title {
    font-family: 'Gilroy', sans-serif;
    font-weight: 500;
    color: #52B1BA;
}
</style>