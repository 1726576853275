import {toursService} from '@/services/tours.js'

const state = {
    tourList: {
      results: []
    },
    tourLocations: {
        results: []
    },
    tourRoutes: {}
};

const mutations = {
  SET_TOUR_LIST(state, payload) {
    state.tourList = payload
  },
  SET_TOUR_LOCATIIONS(state, payload) {
    state.tourLocations = payload
  },
  SET_TOUR_ROUTES(state, payload) {
    state.tourRoutes = payload
  },
};

const actions = {
  async getTourRoutes({ commit }, params) {
    try {
      const res = await toursService.getTourRoutes(params);
      commit("SET_TOUR_ROUTES", res);
    } catch (e) {
      throw e
    }
  },
  async createTourRoute({commit}, data) {
    try {
      return await toursService.createTourRoute(data)
    } catch(e) {
      throw e
    }
  },
  async getBuses({ commit }, data) {
    try { return (await toursService.getBuses(data)) } 
    catch (e) {throw e}
  },
  async createBus({ commit }, data) {
    try {
      return (await toursService.createBus(data));
    } catch (e) {
      throw e
    }
  },
  async getTours({ commit }, params) {
    try {
      const res = await toursService.getTours(params);
      commit("SET_TOUR_LIST", res);
    } catch (e) {
      throw e
    }
  },
  async deleteImage({ commit }, id) {
    try {
      return (await toursService.deleteImage(id)).results;
    } catch (e) {
      throw e
    }
  },
  async getGallery({ commit }, id) {
    try {
      return (await toursService.getGallery(id)).results;
    } catch (e) {
      throw e
    }
  },
  async uploadMultiple({ commit }, data) {
    try {
      await toursService.uploadMultiple(data);
    } catch (e) {
      throw e
    }
  },
  async createTour({ commit }, data) {
    try {
      const res = await toursService.createTour(data);
      return res
    } catch (e) {
      throw e
    }
  },
  async patchTour({ commit }, data) {
    try {
      const res = await toursService.patchTour(data);
      return res
    } catch (e) {
      throw e
    }
  },
  async getTourLocations({ commit }, params) {
    try {
      const res = await toursService.getTourLocations(params);
      commit("SET_TOUR_LOCATIIONS", res);
    } catch (e) {
      throw e
    }
  },
};

const getters = {
  getToursList(state) {
    return state.tourList
  },
  getTourRoutes(state) {
    return state.tourRoutes
  },
  getTourLocations(state) {
    return state.tourLocations.results
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
