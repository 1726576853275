// module for v-autocomlete items inside input-content component
import { busesService } from '@/services/buses.service.js'
import { hotelsService } from '@/services/hotels.service.js'
import { citiesService } from '@/services/cities.service.js'
import { trainService } from '@/services/trains.service.js'
import { stationService } from '@/services/station.service.js'
import { toursService } from '@/services/tours.js'
import { tourOperatorService } from '@/services/tourOperator.service.js'
import { companiesService } from '@/services/companies.service.js'
import { salesService } from '@/services/sales.service.js'

const state = {
  buses: [],
  locations: [],
  tours: [],
  tourOperators: [],
  cities: [],
  hotels: [],
  mainBuses: [],
  railways: [],
  railwaysWithTrain: [],
  stations: [],
  sealChannels: [],
  triggerRequest: 1, 
};

const mutations = {
  SET_TRIGGER_REQUEST(state, payload){
    state.triggerRequest++
  },
  SET_SEAL_CHANNELS(state, payload) {
    state.sealChannels = payload
  },
  SET_RAILWAYS_WITH_TRAIN(state, payload) {
    state.railwaysWithTrain = payload
  },
  SET_STATIONS(state, payload) {
    state.stations = payload
  },
  SET_RAILWAYS(state, payload) {
    state.railways = payload
  },
  SET_MAIN_BUSES(state, payload) {
    state.mainBuses = payload
  },
  SET_HOTELS(state, payload) {
    state.hotels = payload
  },
  SET_CITIES(state, payload) {
    state.cities = payload
  },
  SET_BUSES(state, payload) {
    state.buses = payload
  },
  SET_LOCATIONS(state, payload) {
    state.locations = payload
  },
  SET_TOURS(state, payload) {
    state.tours = payload
  },
  SET_TOUR_OPERATORS(state, payload) {
    state.tourOperators = payload
  }
};

const actions = {
  async getSealChannels({ commit }, params) {
    try {
      let query = {
        company_type: 'agent'
      }
      const {results} = await companiesService.getCompanies(query);
      commit("SET_SEAL_CHANNELS", results);
    } catch (e) {
      throw e
    }
  },
  async getSealChannelsForOperators({ commit }, params) {
    try {
      const results = await salesService.getSales(params);
      commit("SET_SEAL_CHANNELS", results);
    } catch (e) {
      throw e
    }
  },
  async getStations({ commit }, params) {
    try {
      const {results} = await stationService.getStation(params);
      commit("SET_STATIONS", results);
    } catch (e) {
      throw e
    }
  },
  
  async getRailwaysWithTrain({ commit }, params) {
    try {
      const {results} = await trainService.getRailsways(params);
      commit("SET_RAILWAYS_WITH_TRAIN", results);
    } catch (e) {
      throw e
    }
  },
  async getRailways({ commit }, params) {
    try {
      const {results} = await trainService.getRailsways(params);
      commit("SET_RAILWAYS", results);
    } catch (e) {
      throw e
    }
  },
  async getMainBuses({ commit }, params) {
    try {
      const {results} = await busesService.getBuses(params);
      commit("SET_MAIN_BUSES", results);
    } catch (e) {
      throw e
    }
  },
  async getHotels({ commit }, params) {
    try {
      const {results} = await hotelsService.getHotels(params);
      commit("SET_HOTELS", results);
    } catch (e) {
      throw e
    }
  },
  async getBusCities({ commit }, params) {
    try {
      const {results} = await citiesService.getCities(params);
      commit("SET_CITIES", results);
    } catch (e) {
      throw e
    }
  },
  async getTourOperators({ commit }, params) {
    try {
      const {results} = await tourOperatorService.getTourOperators(params);
      commit("SET_TOUR_OPERATORS", results);
    } catch (e) {
      throw e
    }
  },
  async getBuses({ commit }, params) {
    try {
      const {results} = await toursService.getBuses(params);
      commit("SET_BUSES", results);
    } catch (e) {
      throw e
    }
  },
  async getTourLocations({ commit }, params) {
    try {
      const {results} = await toursService.getTourLocations(params);
      commit("SET_LOCATIONS", results);
    } catch (e) {
      throw e
    }
  },
  async getTours({ commit }, params) {
    try {
      const {results} = await toursService.getTours({is_active: true});
      commit("SET_TOURS", results);
    } catch (e) {
      throw e
    }
  },
};

const getters = {
  getTriggerRequest(state){
    return state.triggerRequest
  },
  getSealChannels(state) {
    return state.sealChannels
  },
  getStations(state) {
    return state.stations
  },
  getRailwaysWithTrain(state) {
    return state.railwaysWithTrain.map(el => {
      return { id: el.id, name: `№${el.railway_number} - поезд ${el.train_number}`, type: el.railway_type, hiddenName: el.railway_number }
    })
  },
  getRailways(state) {
    return state.railways.map(el => {
      return { id: el.id, name: el.railway_number, type: el.railway_type }
    })
  },
  getMainBuses(state) {
    return state.mainBuses.map(el => {
      return {id: el.id, name: el.number}
    })
  },
  getHotels(state) {
    return state.hotels
  },
  getBusCities(state) {
    return state.cities
  },
  getBuses(state) {
    return state.buses.map(el => {
      return {id: el.id, name: el.number}
    })
  },
  getTourLocations(state) {
    return state.locations
  },
  getTours(state) {
    return state.tours.map(el => {
      return {id: el.id, name: el.name}
    })
  },
  getTourOperators(state) {
    return state.tourOperators.map(el => {
      return {id: el.id, name: el.name}
    })
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};