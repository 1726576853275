import axios from "axios";

export const contactsService = {
    async getContacts(params) {
        return (await axios.get('/contacts/', { params })).data
    },
    async postContact(data) {
        return (await axios.post('/contacts/', data)).data
    },
    async deleteContact(id) {
        return (await axios.delete(`/contacts/${id}/`)).data
    },
    async patchContact(data) {
        return (await axios.patch(`/contacts/${data.id}/`, data)).data
    },
}

