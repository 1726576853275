import { routes } from "@/router/index.js";

const getters = {
  navigationItems(state, getters, rootState, rootGetters) {
    const filterFn = (route) =>
      !route.hideInNavigation &&
      route.permission.includes(rootGetters["auth/userRole"]);
    const mapFn = (route) => ({
      to: route.path,
      title: route.title,
      name: route.name,
      icon: route.icon,
      hideChildrens: route.hideChildrens,
      children: route.children?.filter(filterFn).map(mapFn),
    });

    return routes.filter(filterFn).map(mapFn);
  },
};
export default {
  namespaced: true,
  getters,
};
