const state = {
    limit: 1,
    bus_type: 'sitting',
    model: {
        seatCount: 0,
        top: null,
        bottom: null,
    },
    busVisible: false,
    clickedSeats: [],
    refreshhKey: 0,
    bus: [],
    sleeping32: [
        {seat_place_type: 'default', name: '13', column: 4, row: 4,},
        {seat_place_type: 'default', name: '11', column: 2, row: 4,},
        {seat_place_type: 'default', name: '9', column: 1, row: 3,},
        {seat_place_type: 'default', name: '7', column: 1, row: 1,},
        {seat_place_type: 'default', name: '5', column: 3, row: 1,},
        {seat_place_type: 'default', name: '3', column: 5, row: 1,},
        {seat_place_type: 'default', name: '1', column: 7, row: 1,},
        {seat_place_type: 'default', name: '14', column: 5, row: 4,},
        {seat_place_type: 'default', name: '15', column: 6, row: 4,},
        {seat_place_type: 'default', name: '16', column: 7, row: 4,},
        {seat_place_type: 'default', name: '12', column: 3, row: 4,},
        {seat_place_type: 'default', name: '10', column: 1, row: 4,},
        {seat_place_type: 'default', name: '8', column: 1, row: 2,},
        {seat_place_type: 'default', name: '6', column: 2, row: 1,},
        {seat_place_type: 'default', name: '4', column: 4, row: 1,},
        {seat_place_type: 'default', name: '2', column: 6, row: 1,},
        
        {seat_place_type: 'wheel', name: 'wheel', column: 9, row: 1,},
        {seat_place_type: 'door', name: 'wheel', column: 8, row: 4,},
    ],
    sleeping36: [
        {seat_place_type: 'default', name: '15', column: 4, row: 4,},
        {seat_place_type: 'default', name: '13', column: 2, row: 4,},
        {seat_place_type: 'default', name: '11', column: 1, row: 3,},
        {seat_place_type: 'default', name: '9', column: 1, row: 1,},
        {seat_place_type: 'default', name: '7', column: 3, row: 1,},
        {seat_place_type: 'default', name: '5', column: 5, row: 1,},
        {seat_place_type: 'default', name: '2', column: 8, row: 1,},
        {seat_place_type: 'default', name: '1', column: 8, row: 2,},
        {seat_place_type: 'default', name: '3', column: 7, row: 1,},
        {seat_place_type: 'default', name: '16', column: 5, row: 4,},
        {seat_place_type: 'default', name: '17', column: 6, row: 4,},
        {seat_place_type: 'default', name: '18', column: 7, row: 4,},
        {seat_place_type: 'default', name: '14', column: 3, row: 4,},
        {seat_place_type: 'default', name: '12', column: 1, row: 4,},
        {seat_place_type: 'default', name: '10', column: 1, row: 2,},
        {seat_place_type: 'default', name: '8', column: 2, row: 1,},
        {seat_place_type: 'default', name: '6', column: 4, row: 1,},
        {seat_place_type: 'default', name: '4', column: 6, row: 1,},
        
        {seat_place_type: 'wheel', name: 'wheel', column: 10, row: 1,},
        {seat_place_type: 'door', name: 'wheel', column: 8, row: 4,},
    ],
    options: [
        {text: 'Сиденье', value: 'default'},
        {text: 'Дверь', value: 'door'},
        {text: 'Удалить', value: 'hidden'},
    ]
};

const mutations = {
    SET_MODEL(state, payload) {
        state.model = {
            seatCount: 0,
            top: null,
            bottom: null,
        }
        state.model = payload
    },
    SET_BUS_TYPE(state, payload) {
        state.bus = []
        state.bus_type = payload
    },
    SET_BACK_SEAT(state) {
        // console.log('SET_BACK_SEAT');
        // state.bus.forEach(seat => {
        //     if (seat.row == Number(state.model.top)+1 && seat.column == 1) {
        //         if (state.model.is_back) {
        //             seat.seat_place_type = 'default'
        //         } else {
        //             seat.seat_place_type = 'empty'
        //         }
        //     }
        // })
        // numerate(state)
    },
    SET_BUS_MODEL(state) {
        if (state.bus_type=='sleeping') {
            if (state.model.seatCount==32) {
                state.bus = state.sleeping32
            } else if (state.model.seatCount==36) {
                state.bus = state.sleeping36
            }
            return
        }
        const bus = []
        const totalRows = Number(state.model.top) + Number(state.model.bottom) + 1
        for (let i = 0; i < state.model.seatCount; i++) {
            bus.push([])
            for (let j = 0; j < totalRows; j++) {
                bus[i].push({seat_place_type: 'default', column: i+1, row: j+1})
            }
        }
        bus.reverse().forEach(cell => {
            if (cell[state.model.top]) {
                cell[state.model.top].seat_place_type = 'empty'
                cell[state.model.top].name = 'empty'
            }
        })
        state.bus = [].concat.apply([], bus);
        state.bus.forEach(seat => {
            if (seat.row == Number(state.model.top)+1 && seat.column == 1) {
                if (state.model.is_back) {
                    seat.seat_place_type = 'default'
                } else {
                    seat.seat_place_type = 'empty'
                }
            }
        })
        state.bus.forEach(seat => {
            if (seat.row == Number(state.model.top)+1 && seat.column == 1) {
                if (state.model.is_back) {
                    seat.seat_place_type = 'default'
                } else {
                    seat.seat_place_type = 'empty'
                }
            }
        })
        numerate(state)
        state.bus.push({seat_place_type: 'wheel', name: 'wheel', column: Number(state.model.seatCount) + 2, row: 1,})
    },
    TRANSFORM_BUS_MODEL_FROM_BACK(state, payload) {
        // payload.bus_seat_places = payload.bus_seat_places.filter(el => el.seat_place_type != 'empty')
        let newModel = {}
        const copy = JSON.parse(JSON.stringify(payload.bus_seat_places))
        // copy.forEach(el => {
        //     if (String(el.name).includes('back')) {
        //         newModel.is_back = true
        //     }
        // })
        // console.table(copy);
        const minRow = copy.map(el => el.row).sort((a, b) => a - b)[0]
        const maxRow = copy.map(el => el.row).sort((a, b) => b - a)[0]
        const bus = []
        const itter = copy.length / maxRow
        for (let i = minRow - 1; i < itter; i++) {
            bus.push(copy.splice(0, maxRow))
        }
        newModel.is_back = false
        bus[bus.length-1].forEach((seat, index) => {
            // is back
            
            // if (seat.name !='empty') {
            //     newModel.is_back = true
            // }
            // state.model.top
            if (seat.name.includes('back')) {
                newModel.top = index
                newModel.is_back = true
            } else if (seat.name.includes('empty')) {
                newModel.top = index
            }
        })
        // console.table(bus);
        newModel.bottom = bus[bus.length-1].length - Number(newModel.top) - 1
        newModel.seatCount = payload.bus_seat_places.map(el => el.column).sort((a, b) => b - a)[0]
        state.bus_type = payload.bus_type
        state.model = {...newModel}
        setTimeout(() => {
            state.bus = payload.bus_seat_places
            state.bus.push({seat_place_type: 'wheel', name: 'wheel', column: Number(state.model.seatCount) + 2, row: 1,})
            // два этажа 
            let arr = JSON.parse(JSON.stringify(state.bus))
            if(state.bus.length == 35) {
                state.bus = [ arr.slice(0, 17), arr.slice(17, 35) ]
            }
            if(state.bus.length == 39) {
                state.bus = [ arr.slice(0, 19), arr.slice(19, 39) ]
            }
        }, 0)
    },
    changeSeat(state, {seat, value}) {
        if (Number(seat.name)) {
            seat.seat_place_type = value
            seat.name = -1
            numerate(state)
        } else if (typeof seat.name == 'string') {
            if (value == 'hidden') {
                state.model.is_back = false
            }
        }
    },
};

const actions = {
};

const getters = {
    getModel(state) {
        return state.model
    },
    getBusModel(state) {
        return state.bus
    },
    postBusModel(state) {
        const results = []
        state.bus.forEach(seat => {
            if (seat.seat_place_type=='hidden') {
                seat.seat_place_type='empty'
                seat.name='empty'
            } else {
                results.push(seat)
            }
        })
        // return results.filter(seat => seat.seat_place_type != 'wheel')
        return state.bus.filter(seat => seat.seat_place_type != 'wheel')
    }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

function numerate(state) {
    let number = 1
    state.bus.forEach(el => {
        if (el.seat_place_type =='default') {
            el.name = number
            number++
        }
    })
    // specifying back seat 
    // need when editing bus, easily acces top/bottom rows
    state.bus.forEach(seat => {
        if (seat.row == Number(state.model.top)+1 && seat.column == 1) {
            if (state.model.is_back) {
                seat.name = `${seat.name}-back`
            }
        }
    })
}

