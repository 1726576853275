const state = {
    loading: false,
    notification: {}
};

const mutations = {
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_NOTIFICATION(state, payload) {
      state.notification = payload
  }
};

const getters = {
  geTloading(state) {
    return state.loading
  },
  getNotification(state) {
    return state.notification
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
