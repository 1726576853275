import axios from "axios";
const url = '/order-requests/'

export const orderRequestService = {
    async getOrderRequests(params) {
        return (await axios.get(`${url}`, {
            params
        })).data
    },
    async createOrderRequest(body) {
        return (await axios.post(`${url}`, body)).data
    },
    async getOrderRequest(id) {
        return (await axios.get(`${url}${id}/`)).data
    },
    async setOrderRequest(body, id) {
        return (await axios.patch(`${url}${id}/`, body)).data
    },
    async deleteOrderRequest(id) {
        return (await axios.delete(`${url}${id}/`)).data
    },
    async changeDiscount(body) {
        return (await axios.post('/request-discounts/', body)).data
    },
    // переотправить заявку на почту
    async resend(body, name) {
        return (await axios.post(`${url}${name}/ticket/`, body)).data
    },
    // увидеть not seen заявки
    async getNotSeenRequests(){
        return (await axios.get('/not-seen-requests/')).data
    }
}