import axios from "axios";

export const stationService = {
    async getStation(params) {
        return (await axios.get('/stations/', { params: params })).data
    },
    async postStation(data) {
        return (await axios.post('/stations/', data)).data
    },
    async deleteStation(id) {
        return (await axios.delete(`/stations/${id}/`)).data
    },
    async patchStation(data) {
        return (await axios.patch(`/stations/${data.id}/`, data)).data
    },
}

