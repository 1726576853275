import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from "./plugins/vuetify";
import rules from "./utils/rules";
import VueAwesomeSwiper from 'vue-awesome-swiper'

import moment from "moment";
import YmapPlugin from "vue-yandex-maps";
import VueMask from 'v-mask'
import VueScrollTo from 'vue-scrollto'

import '@/plugins/axios.js'

import 'swiper/css/swiper.css'
import "@/assets/style/fonts.scss";
import "@/assets/style/base.scss";


Vue.config.productionTip = false;

moment.locale("ru");


Vue.prototype.$copy = obj => JSON.parse(JSON.stringify(obj))
// loading
Vue.prototype.$loading = val => store.commit('ui/SET_LOADING', val);
// validation
Vue.prototype.$rules = rules;

// disable past dates
Vue.prototype.$allowedDates = val => val >= new Date().toISOString().substr(0, 10),


Vue.prototype.$moment = moment;


Vue.use(VueMask);
Vue.use(VueScrollTo, {
  offset: -100,
})
Vue.use(VueAwesomeSwiper)
Vue.use(YmapPlugin, {
  apiKey: "503d351a-faab-4f0d-8b03-1ea984c50e74",
  // apiKey: '',
  lang: "ru_RU",
  coordorder: "latlong",
  version: "2.1",
});


new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
