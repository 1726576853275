<template>
  <v-app>
    <vue-header v-if="routeName !== 'auth'" />
    <div v-if="routeName !== 'auth'" class="page_wrapper">
      <vue-navigation />
      <div></div>
      <div class="main_view">
        <div class="page_content">
          <router-view />
        </div>
      </div>
    </div>
    <div v-else class="auth_page">
      <router-view />
    </div>
    <vue-footer v-if="routeName !== 'auth'" />
    <vue-loading />
    <vue-notification />
  </v-app>
</template>

<script>
import vueHeader from "./components/core/vue-header.vue";
import vueNavigation from "./components/core/vue-navigation";
import vueFooter from "./components/core/vue-footer.vue";
import VueLoading from "./components/ui/vueLoading.vue";
import VueNotification from "./components/ui/vueNotification.vue";
import { authService } from "@/services/auth.js";
export default {
  components: {
    vueFooter,
    vueHeader,
    vueNavigation,
    VueLoading,
    VueNotification,
  },
  name: "App",
  computed: {
    routeName() {
      return this.$route.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.page_wrapper {
  display: grid;
  grid-template-columns: 283px 1fr;
  align-items: start;
  padding-top: 70px;
  min-height: calc(100vh - 130px);
  .main_view {
    background: #f9fafc;
    min-height: 100%;
    min-width: 100%;
    padding: 26px 25px;
    .page_content {
      padding: 20px 32px;
      background: #fff;
      border-radius: 5px;
    }
  }
}

.auth_page {
  padding-top: 70px;
  min-height: calc(100vh - 130px);
}
</style>
