import axios from "axios";

export const hotelsService = {
    async getHotels(params) {
        return (await axios.get('/hotels/', { params })).data
    },
    async postHotel(data) {
        return (await axios.post('/hotels/', data)).data
    },
    async deleteHotel(id) {
        return (await axios.delete(`/hotels/${id}/`)).data
    },
    async patchHotel(data) {
        return (await axios.patch(`/hotels/${data.id}/`, data)).data
    },
}

