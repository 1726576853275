const state = {
    placSeats: [
        [
            { number: 2 },
            { number: 4 },
            { number: 1 },
            { number: 3 },
        ],
        [
            { number: 6 },
            { number: 8 },
            { number: 5 },
            { number: 7 },
        ],
        [
            { number: 10 },
            { number: 12 },
            { number: 9 },
            { number: 11 },
        ],
        [
            { number: 14 },
            { number: 16 },
            { number: 13 },
            { number: 15 },
        ],
        [
            { number: 18 },
            { number: 20 },
            { number: 17 },
            { number: 19 },
        ],
        [
            { number: 22 },
            { number: 24 },
            { number: 21 },
            { number: 23 },
        ],
        [
            { number: 26 },
            { number: 28 },
            { number: 25 },
            { number: 27 },
        ],
        [
            { number: 30 },
            { number: 32 },
            { number: 29 },
            { number: 31 },
        ],
        [
            { number: 34 },
            { number: 36 },
            { number: 33 },
            { number: 35 },
        ],
        // footer
        [
            { number: 54 },
            { number: 53 },
        ],
        [
            { number: 52 },
            { number: 51 },
        ],
        [
            { number: 50 },
            { number: 49 },
        ],
        [
            { number: 48 },
            { number: 47 },
        ],
        [
            { number: 46 },
            { number: 45 },
        ],
        [
            { number: 44 },
            { number: 43 },
        ],
        [
            { number: 42 },
            { number: 41 },
        ],
        [
            { number: 40 },
            { number: 39 },
        ],
        [
            { number: 38 },
            { number: 37 },
        ],
    ],
    coupeSeats: [
        [
            { number: 2 },
            { number: 4 },
            { number: 1 },
            { number: 3 },
        ],
        [
            { number: 6 },
            { number: 8 },
            { number: 5 },
            { number: 7 },
        ],
        [
            { number: 10 },
            { number: 12 },
            { number: 9 },
            { number: 11 },
        ],
        [
            { number: 14 },
            { number: 16 },
            { number: 13 },
            { number: 15 },
        ],
        [
            { number: 18 },
            { number: 20 },
            { number: 17 },
            { number: 19 },
        ],
        [
            { number: 22 },
            { number: 24 },
            { number: 21 },
            { number: 23 },
        ],
        [
            { number: 26 },
            { number: 28 },
            { number: 25 },
            { number: 27 },
        ],
        [
            { number: 30 },
            { number: 32 },
            { number: 29 },
            { number: 31 },
        ],
        [
            { number: 34 },
            { number: 36 },
            { number: 33 },
            { number: 35 },
        ],
    ],
    sittingSeats: [
        [
            {number: 1},
            {number: 2},
            {number: 4},
            {number: 3},
        ],
        [
            {number: 5},
            {number: 6},
            {number: 8},
            {number: 7},
        ],
        [
            {number: 9},
            {number: 10},
            {number: 12},
            {number: 11},
        ],
        [
            {number: 13},
            {number: 14},
            {number: 16},
            {number: 15},
        ],
        [
            {number: 17},
            {number: 18},
            {number: 20},
            {number: 19},
        ],
        [
            {number: 21},
            {number: 22},
            {number: 24},
            {number: 23},
        ],
        [
            {number: 25},
            {number: 26},
            {number: 28},
            {number: 27},
        ],
        [
            {number: 29},
            {number: 30},
            {number: 32},
            {number: 31},
        ],
        [
            {number: 33},
            {number: 34},
            {number: 36},
            {number: 35},
        ],
        
    ],
    luxSeats: [
        [
            { number: 2 },
            { number: 1 },
        ],
        [
            { number: 4 },
            { number: 3 },
        ],
        [
            { number: 6 },
            { number: 5 },
        ],
        [
            { number: 8 },
            { number: 7 },
        ],
        [
            { number: 10 },
            { number: 9 },
        ],
        [
            { number: 12 },
            { number: 11 },
        ],
    ]
};

const mutations = {
    SET_TRAIN_FROM_BACK(state, payload) {
        if (payload.type == 'grandee') return
        let options = {
            sedentary: 'sittingSeats',
            coupe: 'coupeSeats',
            reserved: 'placSeats',
            luxury: 'luxSeats',
        }
        state[options[payload.type]].forEach(col => {
            col.forEach(seat => {
                let item = payload.railway.find(el => el.name == seat.number) 
                seat.item = item
            })
        });
    },
};

const actions = {
};

const getters = {
    getPlacSeats(state) {
        return state.placSeats
    },
    getCoupeSeats(state) {
        return state.coupeSeats
    },
    getSittingSeats(state) {
        return state.sittingSeats
    },
    getLuxSeats(state) {
        return state.luxSeats
    }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};