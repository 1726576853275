import axios from "axios";

export const trainService = {
    async postTrainOrder(data) {
        return (await axios.post(`/railway-orders/`, data)).data
    },
    async bookTrainSeatPlaces(data) {
        return (await axios.patch(`/active-railway-seat-places/booking/${data.id}/`, data))
    },
    async getSingleActiveTrainsPlaces(id) {
        return (await axios.get(`/active-railway-seat-places/${id}/`)).data
    },
    async getSingleActiveTrains(params) {
        return (await axios.get(`/active-railways/${params.id}/`, { params })).data
    },
    async getNearDates(params) {
        return (await axios.get(`/active-railway-near-dates/`, {params})).data
    },
    async getActiveTrains(params) {
        return (await axios.get(`/active-railways/`, {params})).data
    },
    async postRouteRailwayStation(data) {
        return (await axios.post(`/railway-route-stations/`, data)).data
    },
    async deleteRouteRailways(id) {
        return (await axios.delete(`/route-railways/${id}/`)).data
    },
    async deleteRouteRailwayStation(id) {
        return (await axios.delete(`/railway-route-stations/${id}/`)).data
    },
    async patchRouteRailwayStation(data) {
        return (await axios.patch(`/railway-route-stations/${data.id}/`, data)).data
    },
    async postRouteRailways(data) {
        return (await axios.post(`/route-railways/`, data)).data
    },
    async patchRouteRailways(data) {
        return (await axios.patch(`/route-railways/${data.id}/`, data)).data
    },
    async patchRailswayRoutes(data) {
        return (await axios.patch(`/railway-routes/${data.id}/`, data)).data
    },
    async getSingleRailwaysRoutes(id) {
        return (await axios.get(`/railway-routes/${id}/`)).data
    },
    async deleteRailswaysRoute(id) {
        return (await axios.delete(`/railway-routes/${id}/`)).data
    },
    async postRailswaysRoute(data) {
        return (await axios.post(`/railway-routes/`, data)).data
    },
    async getRailswaysRoute(params) {
        return (await axios.get(`/railway-routes/`, {params})).data
    },
    async getRailsways(params) {
        return (await axios.get(`/railways/`, {params})).data
    },
    async getSingleRailsways(id) {
        return (await axios.get(`/railways/${id}/`)).data
    },
    async postRailsways(data) {
        return (await axios.post(`/railways/`, data)).data
    },
    async patchRailsways(data) {
        return (await axios.patch(`/railways/${data.id}/`, data)).data
    },
    async deleteRailway(id) {
        return (await axios.delete(`/railways/${id}/`)).data
    }
}
