import axios from 'axios'
import store from "@/store";
import router from '@/router';


axios.defaults.baseURL = "https://new.qaztrip.kz/api";

axios.interceptors.request.use(config => {
  config.headers["Authorization"] = `Bearer ${store.state.auth.user.access}`;
  return config;
});

axios.interceptors.response.use(
  res => res,
  async error => {
    let message = "Произошла ошибка сервера";
    const notLogged = store.getters["auth/notLogged"];

    const err = error.response?.data?.errors?.detail
    if (error.response?.status === 401 || err == 'Given token not valid for any token type') {
      if (!notLogged) {
        store.commit('auth/SET_CURRENT_USER', {});
        router.push('/auth'); 
      }
    }

    if (error.response?.status >= 500) {
      store.commit('ui/SET_NOTIFICATION', {
        show: true,
        message,
        color: 'error'
      })
    } else {
      let errors = error.response?.data?.errors
      if (error.response.data && error.response.data.error_messages) {
        errors = ''
        error.response.data.error_messages.forEach(element => {
          errors += (element.message + ' ')
        });
      }
      store.commit('ui/SET_NOTIFICATION', {
        show: true,
        message: err || errors || setMessage(errors),
        color: 'error'
      })
    }
    return Promise.reject(error);
  },
);

var label = ''

function setMessage(errors) {
  for (let key in errors) {
    if (typeof errors[key][0] == 'object') {
      setMessage(errors[key][0])
    } else {
      label = `${key}: ${errors[key][0]}`
      return `${key}: ${errors[key][0]}`
    }
  }
  return label
}