<template>
  <v-overlay :value="loading" :z-index='203'>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    computed: {
        ...mapGetters({
            loading: 'ui/geTloading'
        })
    }
}
</script>

<style>

</style>