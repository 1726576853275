export const KEY_CURRENT_USER = "KEY_CURRENT_USER";

function b64Decode(p) {
    return decodeURIComponent(
      atob(p)
        .split("")
        .map(function(c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
  }
  
function b64Encode(p) {
    return Buffer.from(p).toString("base64");
}

export const CurrentUserStorage = {
    set(user) {
        localStorage.setItem(KEY_CURRENT_USER, b64Encode(JSON.stringify(user)));
    },
    get() {
        try {
        return JSON.parse(b64Decode(localStorage.getItem(KEY_CURRENT_USER)));
        } catch (e) {
            return {};
        }
    },
};