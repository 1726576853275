import {
  authService
} from '@/services/auth.js'
import {
  CurrentUserStorage
} from "@/services/localStorage.service";

const state = {
  user: CurrentUserStorage.get(),
  currentUser: {
    role: "tour_agent",
  },
};

const mutations = {
  SET_CURRENT_USER(state, payload) {
    state.user = payload
    CurrentUserStorage.set(state.user);
  }
};

const actions = {
  async login({
    commit
  }, data) {
    try {
      const res = await authService.login(data);
      commit("SET_CURRENT_USER", res);
      const user = await authService.me()
      commit("SET_CURRENT_USER", {
        ...res,
        ...user
      });
    } catch (e) {
      throw e
    }
  },
  async refreshToken({
    commit
  }, data) {
    try {
      const res = await authService.refreshToken(data);
      commit("SET_CURRENT_USER", {
        ...res
      });
      const user = await authService.me()
      commit("SET_CURRENT_USER", {
        ...res,
        ...user
      });
    } catch (e) {
      throw e
    }
  },
};

const getters = {
  notLogged: state => state.user && Object.keys(state.user).length === 0 && Object.getPrototypeOf(state.user) === Object.prototype,
  getUser(state) {
    return state.user
  },
  userRole(state) {
    return state.user.role;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};