import Vue from 'vue'
import Vuex from 'vuex'
import navigation from "./modules/navigation.module";
import auth from "./modules/auth.module";
import ui from "./modules/ui.module";
import tours from "./modules/tours.module";
import lists from "./modules/lists.module";
import busModel from "./modules/busModel.module";
import trainModel from "./modules/trainModel.module";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { navigation, auth, ui, tours, lists, busModel, trainModel },
});
