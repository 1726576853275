import axios from "axios";

export const companiesService = {
    async getCompanies(params) {
        return (await axios.get('/companies/', {
            params
        })).data
    },
    async getSingleCompany(id) {
        return (await axios.get(`/companies/${id}/`)).data
    },
    async postCompany(data) {
        return (await axios.post('/companies/', data)).data
    },
    async patchCompany(data, id) {
        return (await axios.patch(`/companies/${id}/`, data)).data
    },
    async deleteCompany(id) {
        return await axios.delete(`/companies/${id}/`)
    },
    async getTourEmployees(name){
        return (await axios.get(`/companies/employees/${name}/`)).data
    },
    async changePassword(body){
        return (await axios.post(`/change-password/`, body)).data
    },  

    async getMyCompanies(params) {
        return (await axios.get('/companies/my/', {
            params
        })).data
    },
    async chooseCompany(body) {
        return (await axios.post('/companies/choose/', body)).data
    },
    async getTemplates() {
        return (await axios.get('/companies/templates/')).data
    },
    async setTemplates(data) {
        return (await axios.post('/companies/templates/', data))
    }
}