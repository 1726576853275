import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import ru from 'vuetify/es5/locale/ru'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { ru },
    current: 'ru'
  },
  theme: {
    themes: {
      light: {
        primary: "#52B1BA",
        secondary: "#b0bec5",
        accent: "#8c9eff",
        error: "#FF475D",
      },
    },
  },
});
