import axios from "axios";

export const busesService = {
    async postBusOrders(data) {
        return (await axios.post('/bus-orders/', data)).data
    },
    async bookBusSeatPlaces(data) {
        return (await axios.patch(`/active-bus-seat-places/${data.id}/`, data))
    },
    async getNearDates(params) {
        return (await axios.get('/route-list/nearest/', {params})).data
    },
    async getSingleActiveBus(data) {
        return (await axios.get(`/route-list/detail/${data.id}/`, {params: data})).data
    },
    async getBusCard(data) {
        return (await axios.get(`/bus-orders/cards/${data.id}/`, {params: data})).data
    },
    async getActiveBuses(params) {
        return (await axios.get('/route-list/', {params})).data
    },
    async deleteBusHotel(id) {
        return (await axios.delete(`/bus-hotels/${id}/`)).data
    },
    async postBusHotel(data) {
        return (await axios.post('/bus-hotels/', data)).data
    },
    async patchBusHotel(data) {
        return (await axios.patch(`/bus-hotels/${data.id}/`, data)).data
    },
    async patchBusRoute(data) {
        return (await axios.patch(`/bus-routes/${data.id}/`, data))
    },
    async getSingleBusRoutes(id) {
        return (await axios.get(`/bus-routes/${id}/`)).data
    },
    async deleteBusRoute(id) {
        return (await axios.delete(`/bus-routes/${id}/`)).data
    },
    async getBusRoutes(params) {
        return (await axios.get('/bus-routes/', {params})).data
    },
    async postBusRoutes(data) {
        return (await axios.post('/bus-routes/', data)).data
    },
    async deleteBus(id) {
        return (await axios.delete(`/buses/${id}/`)).data
    },
    async getSingleBus(id) {
        return (await axios.get(`/buses/${id}/`)).data
    },
    async postBus(data) {
        return (await axios.post('/buses/', data)).data
    },
    async getBuses(params) {
        return (await axios.get('/buses/', {params})).data
    },
}

