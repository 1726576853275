<template>
  <div class="vue_header">
    <div class="section_wrapper">
      <h1>admin</h1>
      <div class="actions">
        <div v-if="getUser.is_superuser">
          <v-menu
              bottom
              left
              offset-y
              origin="top right"
              v-model="menu"
              transition="scale-transition"
              :close-on-content-click="false"
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn class="ml-2" min-width="0" text v-bind="attrs" v-on="on">
                <v-icon> mdi-domain</v-icon>
              </v-btn>
            </template>
            <v-list :tile="false" nav style="height: 300px; overflow-y: auto">
              <div>
                <v-text-field
                    class="pa-3"
                    solo
                    hide-details
                    v-model="header_input"
                    placeholder="Название компании"
                    @input="loadCompanies"
                >
                </v-text-field>
                <app-bar-item v-for="(n, i) in companies" :key="`item-${i}`">
                  <v-btn
                      style="justify-content: flex-start"
                      block
                      text
                      @click="changeCompany(n)"
                      v-text="n.name"
                  />
                </app-bar-item>
              </div>
            </v-list>
          </v-menu>
        </div>
        <v-badge
            v-if="userRole != 'admin'"
            class="ml-auto"
            overlap
            content="1"
            color="#FF475D"
        >
          <v-btn icon color="grey">
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </v-badge>
        <div
            v-if="userRole != 'admin'"
            @click="goPage"
            class="user"
            v-ripple="{ class: `primary--text` }"
        >
          <img src="@/assets/images/ui/user.svg" alt=""/>
          личный кабинет
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {VHover, VListItem} from "vuetify/lib";
import {mapGetters} from "vuex";
import {companiesService} from "@/services/companies.service.js";
import {authService} from "@/services/auth.js";

export default {
  name: "vueHeader",
  components: {
    AppBarItem: {
      render(h) {
        return h(VHover, {
          scopedSlots: {
            default: ({hover}) => {
              return h(
                  VListItem,
                  {
                    attrs: this.$attrs,
                    class: {
                      "black--text": !hover,
                      "white--text secondary elevation-12": hover,
                    },
                    props: {
                      activeClass: "",
                      dark: hover,
                      link: true,
                      ...this.$attrs,
                    },
                  },
                  this.$slots.default
              );
            },
          },
        });
      },
    },
  },
  data() {
    return {
      menu: false,
      header_input: null,
      companies: [],
    };
  },
  computed: {
    ...mapGetters({
      userRole: "auth/userRole",
      getUser: "auth/getUser",
    }),
  },
  created() {
    this.loadCompanies();
  },
  methods: {
    async changeCompany(value) {
      let res = await companiesService.chooseCompany({
        selected_company: value.id,
      });
      if (res.message == "Success") {
        let refresh = this.$store.state.auth.user.refresh;
        const res = await authService.refreshToken({
          refresh: refresh,
        });
        this.$store.commit("auth/SET_CURRENT_USER", {
          ...res,
        });
        const user = await authService.me();
        this.$store.commit("auth/SET_CURRENT_USER", {
          ...res,
          ...user,
          refresh: refresh,
        });
				this.$store.commit("lists/SET_TRIGGER_REQUEST");
        this.menu = false;
        if(user.role == "admin") this.$router.push("/tours");
        else this.$router.push("/booking/residence");
      }
    },
    async loadCompanies() {
      let params = {
        search: this.header_input,
      };
      this.companies = await companiesService.getMyCompanies(params);
      this.companies.unshift({
        name: "Суперадмин",
        id: null,
      });
    },
    goPage() {
      if (this.$route.path != "/private") {
        this.$router.push("/private");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.vue_header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 70px;
  background: #ffffff;
  box-shadow: 0px 5px 10px rgba(138, 149, 158, 0.1);
  z-index: 2;

  .section_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    h1 {
      font-family: "Gilroy", sans-serif;
      font-weight: 800;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.2em;
      color: #52b1ba;
      text-transform: uppercase;
      cursor: pointer;
    }
  }
}

.actions {
  display: grid;
  align-items: center;
  grid-template-columns: 32px 1fr 1fr;
  grid-column-gap: 42px;
  width: 300px;
}

.user {
  display: grid;
  grid-template-columns: 12px 1fr;
  align-items: center;
  grid-column-gap: 12px;
  font-family: "Roboto";
  font-size: 16px;
  line-height: 19px;
  color: #021011;
  cursor: pointer;
  padding: 12px;
}
</style>
